body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.product-title{
  height: 2.5em; /* Set a fixed height */
  overflow: hidden; /* Hide the overflow */
  text-overflow: ellipsis; /* Add an ellipsis to the end */
  white-space: nowrap; /* Prevent text from wrapping */
}

.rating span{
  margin: 0.1rem;
}

.rating svg{
  color: #FFC0CB;
}

.rating-text {
  font-size: 0.8rem;
  font-weight: 600;
  padding-left: 0.5rem;
}

.carousel-caption {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.5);
}

.custom-input {
  color: red;
  /* Add any other styles you want to customize */
}

a{
  color: black;
  background-color: transparent;
  text-decoration: none;
}

footer{
  margin-top: auto;
}

.image-container {
  position: relative;
  overflow: hidden;
  /* height: 120px; Adjust height as needed */
}

.image-container img {
  /* width: 100%; */
  height: auto;
  transition: transform 0.3s ease-in-out;
}

.image-controls {
  position: absolute;
  top: 30%;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 0px; /* Adjust padding as needed */
  box-sizing: border-box;
}

.arrow-btn {
  background: transparent;
  border: none;
  outline: none;
  font-size: 25px;
  color: #000000;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
  padding: 5px;
}

.arrow-btn:hover {
  color: #000000;
}

.image-controls2 {
  position: absolute;
  top: 40%;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 0px; /* Adjust padding as needed */
  box-sizing: border-box;
}
